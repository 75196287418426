import moment from "moment";
import React, { Component } from "react";
import { connect } from "react-redux";
import { Icon } from "antd";
import SidebarExtension from "../../../../common/components/SidebarExtension";
import CheckIdAngio from "../../../../helpers/CheckIdAngio";
import { getLocal, setLocal } from "../../../../helpers/Local";
import {
  angioPatientSnapshotLoadInit,
  choosePatientOnSchedules,
  getConditionDetailData,
  getLock,
  getPatientSchedules,
  getProviders,
  getVhcLocations,
  get_cpt_code_review,
  get_patient_notes,
  load_intra_operative,
  remove_patient_on_schedules,
  resetStateResource,
  setActionActive,
  setDataPatient,
  setGlobalLoading,
  setGlobalToastr,
  setPatientSnapshotData,
  set_data_template_review,
} from "../../../../store/actions";
import ClinicWorkflowSidebar from "../ClinicWorkflowSidebar";
import DayCalendarCheck from "../DayCalendarCheck";

const resources = [
  {
    id: "angio",
    title: "OBL Procedure",
  },
];

class CheckIn extends Component {
  state = {
    isSidebar: false,
    disable: true,
    events: [],
    resources: [],
    selectedCategories: [],
    searchValue: "",
    schedulesState: [],
    isSelectedPatient: false,
    block: true,
    getLock: false,
    filters: {
      date: moment(new Date()).format("YYYY-MM-DD"),
      location: "",
      provider_id: "",
    },
  };

  componentDidMount = async () => {
    const { date } = this.state.filters;
    const localCheck = JSON.parse(getLocal("oblScheduleParams"));
    const scheduleFilters = {
      date: (localCheck && localCheck?.date) || date,
      location: (localCheck && localCheck?.location) || "",
      provider_id: (localCheck && localCheck?.provider_id) || "",
    };
    this.props.getVhcLocations();
    this.props.getProviders();
    this.loadInitialPaymentJs();
    this.handleEvents(scheduleFilters);
  };

  loadInitialPaymentJs = () => {
    const sqPaymentScript = document.createElement("script");
    sqPaymentScript.src = "https://js.squareup.com/v2/paymentform";
    sqPaymentScript.type = "text/javascript";
    sqPaymentScript.async = false;
    sqPaymentScript.onload = () => {};
    document.getElementsByTagName("head")[0].appendChild(sqPaymentScript);
  };

  handleSidebar = () => {
    this.setState({ isSidebar: true });
  };

  handleCloseSidebar = () => {
    this.setState({ isSidebar: false });
  };

  handleEvents = ({ date, location, provider_id }) => {
    this.setState({
      ...this.state,
      date,
      location,
      provider_id,
    });
    if (!this.props.actionActive?.status) {
      this.props.getPatientSchedules({
        date,
        location,
        provider_id,
        patient_id: "",
        only_clinics: 0,
        only_procedures: 1,
        only_us: 0,
        only_angio: 1,
      });
    }
  };

  onSearch = (searchValue) => {
    this.setState({ searchValue });
    if (searchValue.length > 0) {
      const searchPatientName = (this.props.schedules || []).filter((item) => {
        return item.patientName.toLowerCase().includes(searchValue.toLowerCase());
      });
      this.setState({ schedulesState: [...searchPatientName] });
    } else {
      this.setState({ schedulesState: [...(this.props.schedules || [])] });
    }
  };

  onSelectResource = async (patient_id, appointment_id) => {
    this.props.setGlobalLoading(true);
    this.props.setActionActive({
      ...this.props.actionActive,
      status: false,
    });
    const lock = await this.props.getLock(appointment_id);
    setLocal("current_patient_id", patient_id);
    setLocal("current_appointment_id", appointment_id);
    if (lock?.data.locked === 1) {
      this.props.setGlobalToastr({
        header: "OBL Workflow",
        message: "This procedure has been locked by " + lock?.data?.user,
        type: "failed",
        link: "Notify " + lock?.data?.user,
        exlink: "Force release " + lock?.data?.user,
      });
      this.props.setGlobalLoading(false);
    } else {
      const { goNextTab } = this.props;
      await this.props.resetStateResource();
      await this.props.remove_patient_on_schedules();
      await this.props.get_patient_notes(patient_id);
      const result = await this.props.choosePatientOnSchedules(patient_id);
      if (result) {
        await this.props.setDataPatient(result);
        await this.props.setPatientSnapshotData("resource", { ...result });
      }
      goNextTab();
      //
      let data = [];
      const checkid = await this.props.load_intra_operative(appointment_id);
      const appointment_type = await this.props.get_cpt_code_review(appointment_id);
      if (appointment_type?.appointment_type_id && CheckIdAngio(appointment_type?.appointment_type_id) == true) {
        await setLocal("checkActiveAppointmentType", "active");
      } else {
        await setLocal("checkActiveAppointmentType", "nonActive");
      }
      await this.props.set_data_template_review([]);
      if (checkid && checkid.angio_procedures) {
        checkid.angio_procedures.map(async (r) => {
          const value = await this.props.getConditionDetailData(r.condition_id);
          data = [...data, value];
          await this.props.set_data_template_review([...data]);
        });
      }
      await this.props.angioPatientSnapshotLoadInit(appointment_id);
    }
  };

  // onSelectAppointment = async (appointment_id) => {

  // };

  refreshCalendar = () => {
    const { date, location, provider_id } = this.state;
    this.props.getPatientSchedules({
      date,
      location,
      provider_id,
      patient_id: "",
      only_clinics: 0,
      only_procedures: 1,
      only_us: 0,
      only_angio: 1,
    });
  };

  componentWillReceiveProps(nextProps) {
    const selectedPatientResourceProps = nextProps.selectedPatientResource || {};
    if (Object.keys(selectedPatientResourceProps).length > 0) {
      this.setState({ isSelectedPatient: true });
    } else {
      this.setState({ isSelectedPatient: false });
    }
    if (nextProps.activeReview) {
      this.setState({
        block: true,
      });
      if (nextProps.goNextTabReview) {
        nextProps.goNextTabReview();
      }
    } else {
      this.setState({
        block: false,
      });
    }
    if (nextProps.schedules) {
      this.setState({ schedulesState: [...(nextProps.schedules || [])] });
    }
    if (nextProps?.stateStatusProps && nextProps?.schedules) {
      const indexCheck = (nextProps?.schedules || []).findIndex((r) => r?.id === nextProps?.stateStatusProps?.appointment_id);
      if (indexCheck !== -1) {
        const dataFix = [...nextProps?.schedules];
        dataFix.splice(indexCheck, 1, {
          ...dataFix[indexCheck],
          status: nextProps?.stateStatusProps?.status,
        });
        this.setState({ schedulesState: [...dataFix] });
      }
    }
  }

  sidebarRef = null;
  constructor(props) {
    super(props);
    this.sidebarRef = React.createRef();
  }

  render() {
    const { isSidebar, disable } = this.state;
    const { selectedPatientResource } = this.props;

    return (
      <div className={`clinicWorkflow__main-section ${isSidebar ? "openSidebar" : ""}`} style={{ display: this.props.display ? "flex" : "none" }}>
        <div className="eventsContainer has-sub-header">
          <div className="calendarContainer">
            <DayCalendarCheck
              locations={this.props.locations || []}
              providers={this.props.providers || []}
              events={this.state.schedulesState || []}
              slotProviders={this.props.scheduleProviders || []}
              resources={resources}
              loading={this.props.loadingSchedules || this.props.loadingResource}
              pusherCheckBuild={this.props.pusherCheckBuild || null}
              pusherCheckUnfinalized={this.props.pusherCheckUnfinalized || null}
              fetchEvents={this.handleEvents}
              onSearch={this.onSearch}
              valueSearch={this.state.searchValue}
              onSelectResource={this.onSelectResource}
              calendarType="check-in"
              isOblModule={this.props.isOblModule}
            />
          </div>
        </div>
        {!disable && (
          <ClinicWorkflowSidebar
            ref={this.sidebarRef}
            sidebarIcon={<Icon type="copy" theme="twoTone" twoToneColor="#198038" />}
            resource={selectedPatientResource}
            isShow={isSidebar}
            handleCloseSidebar={this.handleCloseSidebar}
            paymentForm={window.SqPaymentForm}
            refreshCalendar={this.refreshCalendar}
          />
        )}
        {!disable && !isSidebar && (
          <SidebarExtension
            startingWidth="calc(28.3% - 20px + 0.3rem)"
            sidebarReference={this.sidebarRef}
            sidebarToggle={this.toggleSidebar}
            sidebarState={isSidebar}
          />
        )}
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  locations: state.common.vhcLocations,
  providers: state.common.providers,
  schedules: state.patientSchedule.schedules,
  scheduleProviders: state.patientSchedule.providers,
  loadingSchedules: state.patientSchedule.loadingSchedules,
  loadingResource: state.patientSchedule.loadingResource,
  selectedPatientResource: state.patientSchedule.selectedPatientResource,
  activeReview: state.common.activeReview,
  actionActive: state.common.actionActive,
});

const mapDispatchToProps = {
  getVhcLocations,
  getProviders,
  getPatientSchedules,
  angioPatientSnapshotLoadInit,
  choosePatientOnSchedules,
  resetStateResource,
  remove_patient_on_schedules,
  load_intra_operative,
  get_patient_notes,
  getConditionDetailData,
  set_data_template_review,
  setDataPatient,
  get_cpt_code_review,
  getLock,
  setPatientSnapshotData,
  setGlobalLoading,
  setGlobalToastr,
  setActionActive,
};

export default connect(mapStateToProps, mapDispatchToProps)(CheckIn);

import { Icon, Progress, Upload } from "antd";
import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { useLocation } from "react-router-dom";
import ScrollWrapper from "src/common/components/ScrollBar";
import { edit_patient_image } from "src/services/api.services";
import { setGlobalToastr } from "src/store/actions";
const { Dragger } = Upload;

let timer = null;

const UploadImageExamPage = () => {
  const location = useLocation();
  const [imageAdds, setImageAdds] = useState([]);
  const [indexImageAdds, setIndexImageAdds] = useState([]);
  const [uploadProcess, setUploadProcess] = useState({
    show: false,
    percent: 0,
  });
  const dispatch = useDispatch();
  const searchParams = new URLSearchParams(location.search);
  const examId = searchParams.get("exam_id");
  const patientId = searchParams.get("patient_id");

  const handlePost = async (data) => {
    if (examId && data && data.length > 0) {
      let uploadProgress = new Array(data.length).fill(0);

      setUploadProcess({
        show: true,
        percent: 0,
      });

      const updateOverallProgress = () => {
        const totalProgress = uploadProgress.reduce((acc, current) => acc + current, 0);
        const overallProgress = totalProgress / data.length;
        console.log(totalProgress, "Total progress");
        console.log(`Overall Progress: ${overallProgress}`);
        setUploadProcess({
          show: true,
          percent: overallProgress,
        });
      };

      let uploadResults = [];
      for (let index = 0; index < data.length; index++) {
        const file = data[index];
        const formData = new FormData();
        formData.append("patient_id", patientId);
        formData.append("exam_id", examId);
        formData.append("files[]", file.originFileObj);

        try {
          const result = await edit_patient_image(formData, (progressEvent) => {
            const progress = (progressEvent.loaded / progressEvent.total) * 100;
            uploadProgress[index] = progress;
            updateOverallProgress();
          });

          setIndexImageAdds((prevIndexes) => [...prevIndexes, index]);
          uploadResults.push(result);
        } catch (error) {
          console.error(`Error uploading file at index ${index}:`, error);
          uploadResults.push(false); // Push false if upload failed
        }
      }

      const allUploadsSuccessful = uploadResults.every((result) => result);
      if (allUploadsSuccessful) {
        setUploadProcess({
          show: true,
          percent: 100,
        });
        dispatch(
          setGlobalToastr({
            header: "Edit Exam",
            message: "Edit Exam successfully!",
            type: "success",
          })
        );
      } else {
        dispatch(
          setGlobalToastr({
            header: "Edit Exam",
            message: "Edit Exam failed!",
            type: "failed",
          })
        );
        setUploadProcess({
          show: false,
          percent: 0,
        });
      }
    } else {
      return;
    }
  };

  const handleUploadImages = async (info) => {
    clearTimeout(timer);
    timer = setTimeout(async () => {
      if (patientId && examId) {
        let newFileList = [...info.fileList];
        newFileList = newFileList.map((file) => {
          if (file.response) {
            file.url = file.response.url;
          }
          return file;
        });
        setImageAdds(newFileList);
        handlePost(newFileList);
      } else {
        dispatch(
          setGlobalToastr({
            header: "Save New Exam",
            message: "Save new Exam failed!",
            type: "failed",
          })
        );
      }
    }, 1000);
  };

  return (
    <div className="hospitalContent mainPage">
      <div className={`clinicWorkflow__main-container`}>
        <div className="main multi-main">
          <div
            className={`mainContainer`}
            style={{
              display: "flex",
              width: "100%",
              flexDirection: "column",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <Dragger
              showUploadList={false}
              beforeUpload={() => false}
              fileList={imageAdds}
              onRemove={(file) => {
                const index = imageAdds.indexOf(file);
                const newFileList = imageAdds.slice();
                newFileList.splice(index, 1);
                setImageAdds(newFileList);
              }}
              onChange={handleUploadImages}
              multiple={true}
              directory={true}
              className="text-right"
              style={{
                display: "flex",
                width: "100%",
                justifyContent: "center",
                alignItems: "center",
                flexDirection: "column",
              }}
            >
              <div style={{ display: "flex", width: "100%", justifyContent: "center", alignItems: "center", flexDirection: "column" }}>
                <p className="ant-upload-drag-icon" style={{ margin: 0 }}>
                  <Icon type="cloud-upload" />
                </p>
                <p className="ant-upload-text">Click or drag file to this area to upload</p>
                <p className="ant-upload-hint">
                  Support for a single or bulk upload. Strictly prohibited from uploading company data or other banned files.
                </p>
              </div>
            </Dragger>
            {uploadProcess.show ? (
              <div style={{ width: "100%", marginTop: 10 }}>
                <Progress percent={uploadProcess?.percent} status="active" />
              </div>
            ) : null}
            <ScrollWrapper css="no-padding x-hidden">
              {imageAdds && imageAdds.length > 0 ? (
                <div className="scroll-image-list-clinic">
                  {(imageAdds || []).map((file, index) => (
                    <div
                      key={index}
                      style={{
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "space-between",
                        width: "100%",
                        marginBottom: 10,
                        borderBottom: "1px solid #d6d6d6",
                        paddingBottom: 10,
                      }}
                    >
                      <div style={{ width: "calc(100%) - 50px", paddingLeft: 20 }}>
                        <p style={{ display: "flex", justifyContent: "space-around" }}>
                          {indexImageAdds.includes(index) && (
                            <Icon type="check-circle" theme="twoTone" twoToneColor="#24A148" style={{ marginTop: "3px", alignItems: "flex-start" }} />
                          )}
                          <b style={{ marginLeft: "5px" }}>Name: </b> {file.name}
                        </p>
                      </div>
                      {/* <div style={{ width: 50 }}>
                        <DeleteIconButton
                          handleClick={(e) => {
                            const newFileList = imageAdds.filter((_, i) => i !== index);
                            setImageAdds([...newFileList]);
                          }}
                          icon={"can"}
                          extraStyle={{
                            display: "inline-block",
                            right: "0.75rem",
                            marginRight: 20,
                            cursor: "pointer",
                            marginRight: 0,
                          }}
                        />
                      </div> */}
                    </div>
                  ))}
                </div>
              ) : null}
            </ScrollWrapper>
          </div>
        </div>
      </div>
    </div>
  );
};

export default UploadImageExamPage;

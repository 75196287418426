import React, { useEffect, useMemo, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import { Icon } from "antd";
import DataTable from "../../../../common/components/DataTable/DataTable";
import { getInventoryCostHistory, setGlobalLoading, setInventoryData } from "../../../../store/actions";
import { StaticResource } from "../../constant";
import InventoryFilter from "./InventoryFilter";
import InventorySearchSidebar from "./InventorySearchSidebar";

const filterDataByOption = (data, option) => {
  const { specific_location, location, inventory } = option;
  let filteredData = data;
  if (!specific_location && !location && !inventory) {
    return filteredData;
  }
  if (inventory) {
    filteredData = filteredData?.filter((d) => d.inventory_units >= inventory);
  }
  if (specific_location) {
    filteredData = filteredData?.filter((d) => d.specific_location === specific_location);
  }
  if (location) {
    filteredData = filteredData?.filter((d) => d.location === location);
  }
  return filteredData;
};

const InventorySearch = ({ data, title, locations }) => {
  const [isSidebar, setIsSidebar] = useState(false);
  const ref = useRef(null);
  const [activeIndex, setRow] = useState(-1);
  const [offset, setOffset] = useState(0);
  const [tableRows, setTableRows] = useState([]);
  const inventories = useSelector((state) => state.inventory.inventories);
  const inventory = useSelector((state) => state.inventory.inventory);
  const filterOption = useSelector((state) => state.inventory.inventoryFilter);
  const loadingSearch = useSelector((state) => state.inventory.loadingAPI);

  const dispatch = useDispatch();

  useEffect(() => {
    if (inventories && inventories.length) {
      setTableRows(filterDataByOption(inventories, filterOption));
    }
    return () => {
      setTableRows([]);
    };
  }, [inventories, filterOption]);

  useEffect(() => {
    dispatch(setGlobalLoading(loadingSearch));
  }, [loadingSearch]);

  const handleSidebar = () => {
    setIsSidebar(true);
  };

  const handleCloseSidebar = () => {
    setIsSidebar(false);
  };

  const handleRow = (index, row) => {
    setRow(index);
    dispatch(setInventoryData("inventory", rowData[index]));
    dispatch(getInventoryCostHistory(rowData[index].id, rowData[index].type));
    handleSidebar();
  };

  const rowData = useMemo(() => {
    if (!tableRows || !tableRows.length) {
      return [];
    }
    if (tableRows && tableRows.length && offset <= tableRows.length) {
      const count = Math.min(50, tableRows.length - offset);
      return tableRows.slice(0, offset + count);
    }
  }, [offset, tableRows]);

  const onScroll = (values) => {
    if (values.scrollTop > values.scrollHeight - values.clientHeight - 80 && offset < tableRows.length) {
      const count = Math.min(50, tableRows.length - offset);
      setOffset(offset + count);
    }
  };

  const handleUpdateLocal = (value, isDelete) => {
    if (isDelete) {
      const indexChange = tableRows.findIndex((r) => r?.id === value);
      tableRows.splice(indexChange, 1);
      setTableRows([...tableRows]);
      return;
    }
    if (value) {
      const indexChange = tableRows.findIndex((r) => r?.id === value?.id);
      if (indexChange !== -1) {
        tableRows[indexChange] = value;
        setTableRows([...tableRows]);
      }
    }
  };

  const sidebarRef = useRef();

  return (
    <div className="clinicWorkflow__main-section">
      <div className="eventsContainer has-sub-header">
        <div className="fullContainer pt-2">
          <InventoryFilter
            resetState={() => {
              setTableRows([]);
            }}
            locations={locations}
          />
          <div className="tableSection">
            <div ref={ref} className="tableContainer">
              <DataTable
                title="inventory_search"
                hideHeader={true}
                disableDelete={true}
                loadMore={onScroll}
                defaultActive={-1}
                labels={["Name", "Quantity", "Location", "Specific Location"]}
                widths={["50%", "130px", "130px", "calc(50% - 260px)"]}
                columns={[
                  { sortable: false, key: "name" },
                  { sortable: false, key: "quantity" },
                  { sortable: false, key: "location" },
                  { sortable: false, key: "specific_location" },
                ]}
                rowData={rowData?.map((row, index) => {
                  return {
                    name: (
                      <div
                        className="iconField td with-icon"
                        style={{
                          display: "flex",
                          paddingLeft: "0.25rem",
                          justifyContent: "flex-start",
                          alignItems: "center",
                        }}
                      >
                        {row?.type === "narcotic" ? (
                          <Icon type="experiment" theme="twoTone" twoToneColor="#0043CE" />
                        ) : row?.type === "angiographic" || row?.type === "Angiographic" ? (
                          <Icon type="tag" theme="twoTone" twoToneColor="#6929C4" />
                        ) : (
                          <Icon type="shop" theme="twoTone" twoToneColor="#9F1853" />
                        )}
                        <p style={{ paddingLeft: "0.75rem" }}>{row?.name || ""}</p>
                      </div>
                    ),
                    quantity: row?.inventory_units,
                    location: row?.location,
                    specific_location: row?.specific_location,
                  };
                })}
                handleClickRow={(row, index) => handleRow(index, row)}
              />
            </div>
          </div>
        </div>
      </div>
      {inventory && activeIndex >= 0 && (
        <InventorySearchSidebar
          ref={sidebarRef}
          handleUpdateLocal={handleUpdateLocal}
          resource={StaticResource}
          isShow={isSidebar}
          handleCloseSidebar={handleCloseSidebar}
          locations={locations}
        />
      )}
    </div>
  );
};

export default InventorySearch;

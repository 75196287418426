/* eslint-disable no-unused-expressions */
import * as Sentry from "@sentry/react";
import { BrowserTracing } from "@sentry/tracing";
import "draft-js-mention-plugin/lib/plugin.css";
import "material-design-icons/iconfont/material-icons.css";
import moment from "moment";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import packageJson from "../package.json";
import "./App.scss";
import PusherCheck from "./common/components/PusherCheck";
import PusherConnector from "./common/components/PusherScan/pusherConnector";
import ToastrMsg from "./common/components/Toast";
import { getLocal, removeLocal, setLocal } from "./helpers/Local";
import AppRouters from "./routers";
import { useHistory } from "react-router-dom";

import {
  filterLocations,
  filterProviders,
  get_CPT_CT,
  getCurrentPatient,
  getCurrentProvider,
  getCurrentUserInfo,
  getLocations,
  getPharmacies,
  getProcedureData,
  getProviders,
  getReferralConditions,
  insurance_types,
  search_insurance_companies,
  setGlobalErrorMessage,
  setGlobalLoading,
  setGlobalToastr,
  showButtonBack,
  resetAllStatus,
} from "./store/actions";
import { Modal } from "antd";

Sentry.init({
  dsn: "https://844866e8201443d0b4ad7eb6948fe429@o77591.ingest.sentry.io/6400627",
  integrations: [new BrowserTracing()],
  beforeSend: (event, hint) => {
    hint.attachments = [{ filename: "screenshot.png" }];
    return event;
  },
  tracesSampler: (samplingContext) => {},
  tracesSampleRate: 1.0,
});

const consoleWarn = console.error;
const SUPPRESSED_WARNINGS = ["Failed prop type", "React does not recognize the", "Received"];

console.error = function filterWarnings(msg, ...args) {
  if (!SUPPRESSED_WARNINGS.some((entry) => entry && msg && typeof msg !== "object" && msg?.includes(entry))) {
    consoleWarn(msg, ...args);
  }
};

function App() {
  const error = useSelector((state) => state.common.globalErrorMessage);
  const globalLoading = useSelector((state) => state.common.globalLoading);
  const globalMessage = useSelector((state) => state.common.globalMessage);
  const uploadLadingBlock = useSelector((state) => state.common.uploadLadingBlock);
  const currentUserInfo = useSelector((state) => state.common.currentUserInfo);
  const activeSupportTicket = useSelector((state) => state.common.activeSupportTicket);
  const history = useHistory(); // or useNavigate for react-router v6

  const clearCacheData = () => {
    // if (caches) {
    //   caches.keys().then((names) => {
    //     names.forEach((name) => {
    //       caches.delete(name);
    //     });
    //   });
    // }
  };

  const [pusherChannel, setPusherChannel] = useState(false);
  const [pusherConnector, setPusherConnnector] = useState();
  const dispatch = useDispatch();
  const userToken = getLocal("cvai-auth-token");
  const userRole = getLocal("cvai-auth-role");
  const authId = getLocal("cvai-current-user");
  setLocal("patient_document_id", null);
  let logoutTimer;

  const handleLogout = () => {
    removeLocal("cvai-auth-token");
    removeLocal("cvai-token-type");
    removeLocal("cvai-token-expires");
    removeLocal("cvai-auth-role");
    removeLocal("cvai-current-user-provider-name");
    removeLocal("cvai-current-user-name");
    removeLocal("cvai-current-user");
    removeLocal("cvai-current-user_status");
    removeLocal("patien_id_search");
    removeLocal("current_patient_id");
    dispatch(resetAllStatus());

    setTimeout(() => {
      history.push("/login");
    }, 1000);
  };

  const resetTimer = () => {
    if (logoutTimer) clearTimeout(logoutTimer);
    // Set a new timer for 1 hour (3600000 ms)
    logoutTimer = setTimeout(handleLogout, 3600000); // 1 hour
  };

  const setupInactivityEvents = () => {
    window.addEventListener("mousemove", resetTimer);
    window.addEventListener("keydown", resetTimer);
    window.addEventListener("scroll", resetTimer);
    window.addEventListener("click", resetTimer);
  };

  const removeInactivityEvents = () => {
    window.removeEventListener("mousemove", resetTimer);
    window.removeEventListener("keydown", resetTimer);
    window.removeEventListener("scroll", resetTimer);
    window.removeEventListener("click", resetTimer);
  };

  const fetchApiCheck = async () => {
    if (userRole === "patient") {
      await dispatch(getCurrentPatient());
    } else if (userRole === "provider") {
      await dispatch(getCurrentProvider());
    }
    if (authId) {
      await dispatch(getCurrentUserInfo(authId));
    }
  };

  const fetchData = async () => {
    if (userRole) {
      await dispatch(setGlobalLoading(true));
      await dispatch(getReferralConditions());
      await dispatch(getProviders());
      await dispatch(insurance_types());
      await dispatch(getLocations());
      await dispatch(search_insurance_companies(""));
      await dispatch(getPharmacies(""));
      await dispatch(getProcedureData());
      await dispatch(get_CPT_CT());
      await dispatch(filterProviders(""));
      await dispatch(filterLocations("", "all"));
      await dispatch(setGlobalLoading(false));
    }
  };

  useEffect(() => {
    if (process.env.NODE_ENV !== "development") {
      const pusher = new PusherConnector();
      var channelName = "support-messages";
      const channel = pusher.createChannel(channelName);
      setPusherConnnector(pusher);
      setPusherChannel(channel);
    }

    setupInactivityEvents();
    resetTimer(); // Start the inactivity timer

    return () => {
      clearTimeout(logoutTimer);
      removeInactivityEvents(); // Cleanup event listeners
    };
  }, []);

  useEffect(() => {
    if (process.env.NODE_ENV !== "development") {
      if (pusherChannel && pusherChannel.bind && pusherConnector) {
        const eventName = "new-ticket";

        pusherConnector.unbindEvent(pusherChannel, eventName);
        pusherConnector.bindEvent(pusherChannel, eventName, async (callback) => {
          const data = await callback;
          if (currentUserInfo.is_support) {
            dispatch(
              setGlobalToastr({
                header: "New Support Ticket",
                message: "Subject: " + data?.subject,
                type: "warning",
              })
            );
          }
        });
      }
    }
  }, [pusherChannel, pusherConnector, currentUserInfo]);

  useEffect(() => {
    if (process.env.NODE_ENV !== "development") {
      if (pusherChannel && pusherChannel.bind && pusherConnector) {
        const eventName = "new-message";

        pusherConnector.unbindEvent(pusherChannel, eventName);
        pusherConnector.bindEvent(pusherChannel, eventName, async (callback) => {
          const data = await callback;
          if (currentUserInfo?.id == data?.user_id && activeSupportTicket != data?.support_id) {
            dispatch(
              setGlobalToastr({
                header: "New Support Message",
                message: "Subject: " + data?.subject,
                type: "warning",
              })
            );
          }
        });
      }
    }
  }, [pusherChannel, pusherConnector, currentUserInfo, activeSupportTicket]);

  useEffect(() => {
    if (currentUserInfo) {
      const dataOBL = JSON.parse(getLocal("oblScheduleParams"));
      if (!dataOBL?.date || !dataOBL) {
        setLocal(
          "oblScheduleParams",
          JSON.stringify({
            date: moment().format("MM/DD/YYYY"),
            location: "",
            provider_id: currentUserInfo?.provider_id,
          })
        );
      }
      // update state local filter clinic;
      const dataClinic = JSON.parse(getLocal("scheduleParams"));
      if (!dataClinic?.date || !dataClinic) {
        setLocal(
          "scheduleParams",
          JSON.stringify({
            date: moment().format("MM/DD/YYYY"),
            location: "",
            provider_id: currentUserInfo?.provider_id,
          })
        );
      }
    }
  }, [currentUserInfo]);

  useEffect(() => {
    dispatch(showButtonBack(false));
    if (userToken && authId) {
      fetchApiCheck();
      // update state local filter OBL;
    } else if (!userToken || !authId) {
      removeLocal("cvai-auth-token");
      removeLocal("cvai-token-type");
      removeLocal("cvai-token-expires");
    }
    let version = getLocal("version");
    if (!version || version !== packageJson?.version) {
      setLocal("version", packageJson?.version);
      clearCacheData();
    }
  }, []);

  useEffect(() => {
    if (currentUserInfo && Object.keys(currentUserInfo).length > 0) {
      if (window.location.pathname != "/upload-image-exam") {
        fetchData();
      }
    }
  }, [currentUserInfo]);

  useEffect(() => {
    if (error) {
      setTimeout(() => {
        dispatch(setGlobalErrorMessage(null));
      }, 3000);
    }
  }, [error]);

  useEffect(() => {
    if (globalMessage && !globalMessage?.disableAutoHide) {
      setTimeout(() => {
        dispatch(setGlobalToastr(null));
      }, globalMessage?.timeOff || 10000);
    }
  }, [globalMessage]);

  setTimeout(() => {
    document.querySelectorAll(".ant-input-affix-wrapper input.ant-input").forEach((elem) => {
      elem.addEventListener("focus", (event) => {
        if (event?.srcElement?.parentNode?.className && event?.srcElement?.parentNode?.className?.split(" ").includes("headerSearch")) return;
        event.srcElement.setAttribute("style", "border:2px solid #0f62fe !important");
        event.srcElement.parentNode.parentNode.style.border = "none";
      });
    });
    document.querySelectorAll(".ant-input-affix-wrapper input.ant-input").forEach((elem) => {
      elem.addEventListener("blur", (event) => {
        if (event?.srcElement?.parentNode?.className && event?.srcElement?.parentNode?.className?.split(" ").includes("headerSearch")) return;
        event.srcElement.setAttribute("style", "border:none !important");
        event.srcElement.parentNode.parentNode.style.border = "1px solid #dfe3e6";
      });
    });
  }, 500);

  setTimeout(() => {
    document.querySelectorAll("input.ant-input").forEach((elem) => {
      elem.addEventListener("focus", (event) => {
        if (event?.srcElement?.parentNode?.className && event?.srcElement?.parentNode?.className?.split(" ").includes("input-error")) return;
        if (event?.srcElement?.parentNode?.className && event?.srcElement?.parentNode?.className?.split(" ").includes("headerSearch")) return;
        event.srcElement.style.setProperty("border", "2px solid #0f62fe", "important");
      });
    });
    document.querySelectorAll("input.ant-input").forEach((elem) => {
      elem.addEventListener("blur", (event) => {
        if (event?.srcElement?.parentNode?.className && event?.srcElement?.parentNode?.className?.split(" ").includes("input-error")) return;
        if (event?.srcElement?.parentNode?.className && event?.srcElement?.parentNode?.className?.split(" ").includes("headerSearch")) return;
        event.srcElement.style.setProperty("border", "none", "important");
      });
    });
  }, 3000);

  document.querySelectorAll("div.ant-select").forEach((elem) => {
    elem.addEventListener("focus", (event) => {
      if (event.srcElement.className.split(" ").includes("input-error")) return;
      event.srcElement.setAttribute("style", "border:2px solid #0f62fe !important");
      event.srcElement.parentNode.parentNode.style.border = "none";
    });
  });
  document.querySelectorAll("div.ant-select").forEach((elem) => {
    elem.addEventListener("blur", (event) => {
      if (event.srcElement.className.split(" ").includes("input-error")) return;
      event.srcElement.setAttribute("style", "border:none !important");
      event.srcElement.parentNode.parentNode.style.border = "1px solid #dfe3e6";
    });
  });

  return (
    <Sentry.ErrorBoundary fallback={<div>An error has occurred</div>} showDialog>
      <div className="App" id="app" style={{ position: "relative" }}>
        <AppRouters />
        {process.env.NODE_ENV !== "development" && <PusherCheck></PusherCheck>}
        {error && error?.message && (
          <ToastrMsg type={error?.status ? error?.status.toUpperCase() : "failed"} msg={error?.message} status={"failed"} />
        )}
        {globalMessage && globalMessage?.message && (
          <ToastrMsg
            type={globalMessage?.header ? globalMessage?.header : "Success"}
            msg={globalMessage?.message}
            link={globalMessage?.link}
            exlink={globalMessage?.exlink}
            status={globalMessage?.type ? globalMessage?.type : "success"}
          />
        )}
      </div>
    </Sentry.ErrorBoundary>
  );
}

export default App;

import { withStyles } from "@material-ui/core/styles";
import React, { PureComponent } from "react";
import { connect } from "react-redux";
// import axios from 'axios'
import Collapse from "@material-ui/core/Collapse";
import Drawer from "@material-ui/core/Drawer";
import IconButton from "@material-ui/core/IconButton";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import ListItemText from "@material-ui/core/ListItemText";
import MenuIcon from "@material-ui/icons/Menu";
import {
  mdiAngleAcute,
  mdiCheckboxIntermediate,
  mdiContrastBox,
  mdiCursorDefault,
  mdiCursorPointer,
  mdiEllipse,
  mdiEyedropper,
  mdiGesture,
  mdiInvertColors,
  mdiMagnify,
  mdiRectangle,
  mdiRuler,
} from "@mdi/js";
import Icon from "@mdi/react";
import { isMobile } from "react-device-detect";
import {
  activeDcm,
  activeDcmIndex,
  activeMeasurements,
  activeShowValue,
  dcmIsOpen,
  dcmTool,
  localfileStore,
  setDicomdir,
  setLayout,
  setZippedFile,
} from "../../../store/actions";
import DicomViewer from "./components/dicomviewer";
import { getFileExtReal, getFileNameCorrect, getSettingsFsView } from "./functions";

import "./App.css";

const drawerWidth = 240;
const iconColor = "#FFFFFF";
let iconTool = null;

const styles = (theme) => ({
  "@global": {
    body: {
      backgroundColor: theme.palette.common.black,
    },
  },

  grow: {
    flexGrow: 1,
  },

  root: {
    display: "flex",
  },

  menuButton: {
    marginRight: theme.spacing(2),
    color: "#fff",
  },

  title: {
    flexGrow: 1,
  },

  appBar: {
    position: "relative",
    zIndex: theme.zIndex.drawer + 1,
    transition: theme.transitions.create(["width", "margin"], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
  },

  appBarShift: {
    marginLeft: drawerWidth,
    width: `calc(100% - ${drawerWidth}px)`,
    transition: theme.transitions.create(["width", "margin"], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },

  hide: {
    display: "none",
  },

  drawer: {
    width: drawerWidth,
    flexShrink: 0,
    whiteSpace: "nowrap",
  },

  drawerOpen: {
    width: drawerWidth,
    transition: theme.transitions.create("width", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },

  drawerClose: {
    transition: theme.transitions.create("width", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    overflowX: "hidden",
    width: theme.spacing(7) + 1,
    [theme.breakpoints.up("sm")]: {
      width: theme.spacing(9) + 1,
    },
  },

  // Loads information about the app bar, including app bar height
  toolbar: theme.mixins.toolbar,

  content: {
    flexGrow: 1,
    padding: theme.spacing(3),
  },

  listItemText: {
    fontSize: "0.85em",
    marginLeft: "-20px",
  },
});

class App extends PureComponent {
  constructor(props) {
    super(props);
    this.file = null;
    this.url = null;
  }

  state = {
    anchorElLayout: null,
    openImageEdit: false,
    visibleMainMenu: false,
    visibleHeader: false,
    visibleSettings: false,
    visibleToolbar: true,
    visibleOpenUrl: false,
    visibleToolbox: false,
    visibleMeasure: false,
    visibleClearMeasureDlg: false,
    visibleAbout: false,
    visibleDicomdir: false,
    visibleFileManager: false,
    visibleZippedFileDlg: false,
    visibleDownloadZipDlg: false,
    activeShow: false,
    toolState: 1,
    toolActive: "notool",
    // loading: 0,
  };

  componentDidMount() {
    // Need to set the renderNode since the drawer uses an overlay
    // this.dialog = document.getElementById('drawer-routing-example-dialog')
    window.scrollTo(0, 0);
  }

  showAppBar = () => {
    window.scrollTo(0, 0);
  };

  toolExecute = (tool) => {
    this.setState({
      toolActive: tool,
    });
    this.hideMainMenu();
  };

  showMainMenu = () => {
    this.setState({ visibleMainMenu: true });
  };

  hideMainMenu = () => {
    this.setState({ visibleMainMenu: false });
  };

  toggleMainMenu = () => {
    const visibleMainMenu = this.state.visibleMainMenu;
    // const visibleFileManager = this.state.visibleFileManager
    if (getSettingsFsView() === "left") {
      this.setState({
        visibleMainMenu: !visibleMainMenu,
        visibleFileManager: false,
      });
    } else {
      this.setState({ visibleMainMenu: !visibleMainMenu });
    }
  };

  handleScroll = () => {
    if (this.props.activeShow) {
      this.hideMainMenu();
    }
    this.props.activeShowValue(!this.props.activeShow);
  };

  render() {
    const { classes } = this.props;
    const { toolActive } = this.state;

    const primaryClass = { primary: classes.listItemText };
    const iconSize = "1.2rem";
    const openImageEdit = this.state.openImageEdit;
    const visibleMainMenu = this.state.visibleMainMenu;

    return (
      <div
        style={{
          height: "100%",
        }}
      >
        <div className="size-dicom-view">
          <div
            style={{
              position: "absolute",
              top: 1,
              left: 50,
              zIndex: 100,
            }}
          >
            <IconButton edge="start" className={classes.menuButton} color="inherit" aria-label="menu" onClick={this.toggleMainMenu}>
              <MenuIcon />
            </IconButton>
          </div>
          <div
            style={{
              width: "100%",
              height: "100%",
              position: "relative",
              color: "#FFFFFF",
              textShadow: "1px 1px #000000",
            }}
            onContextMenu={() => false}
            className="cornerstone-enabled-image"
          >
            <DicomViewer toolActive={toolActive} />
          </div>

          <Drawer className="size-drawer-dicom" variant="persistent" open={visibleMainMenu} onClose={this.toggleMainMenu}>
            <div className={classes.toolbar}>
              <List dense={true}>
                <ListItem button onClick={() => this.toolExecute("notool")} disabled={false}>
                  <ListItemIcon>
                    <Icon path={mdiCursorDefault} size={iconSize} color={toolActive == "notool" ? "green" : iconColor} />
                  </ListItemIcon>
                  <ListItemText classes={primaryClass} primary="No tool" />
                </ListItem>
                <ListItem button onClick={() => this.toolExecute("Wwwc")} disabled={false}>
                  <ListItemIcon>
                    <Icon path={mdiContrastBox} size={iconSize} color={toolActive == "Wwwc" ? "green" : iconColor} />
                  </ListItemIcon>
                  <ListItemText classes={primaryClass} primary="Contrast" />
                </ListItem>
                <ListItem button onClick={() => this.toolExecute("Pan")} disabled={false}>
                  <ListItemIcon>
                    <Icon path={mdiCursorPointer} size={iconSize} color={toolActive == "Pan" ? "green" : iconColor} />
                  </ListItemIcon>
                  <ListItemText classes={primaryClass} primary="Pan" />
                </ListItem>
                <ListItem button onClick={() => this.toolExecute("Zoom")} disabled={false}>
                  <ListItemIcon>
                    <Icon path={mdiMagnify} size={iconSize} color={toolActive == "Zoom" ? "green" : iconColor} />
                  </ListItemIcon>
                  <ListItemText classes={primaryClass} primary="Zoom" />
                </ListItem>
                <ListItem button onClick={() => this.toolExecute("Magnify")} disabled={false}>
                  <ListItemIcon>
                    <Icon path={mdiCheckboxIntermediate} size={iconSize} color={toolActive == "Magnify" ? "green" : iconColor} />
                  </ListItemIcon>
                  <ListItemText classes={primaryClass} primary="Magnify" />
                </ListItem>
                <ListItem button onClick={() => this.toolExecute("Length")} disabled={false}>
                  <ListItemIcon>
                    <Icon path={mdiRuler} size={iconSize} color={toolActive == "Length" ? "green" : iconColor} />
                  </ListItemIcon>
                  <ListItemText classes={primaryClass} primary="Length" />
                </ListItem>
                <ListItem button onClick={() => this.toolExecute("Probe")} disabled={false}>
                  <ListItemIcon>
                    <Icon path={mdiEyedropper} size={iconSize} color={toolActive == "Probe" ? "green" : iconColor} />
                  </ListItemIcon>
                  <ListItemText classes={primaryClass} primary="Probe" />
                </ListItem>
                <ListItem button onClick={() => this.toolExecute("Angle")} disabled={false}>
                  <ListItemIcon>
                    <Icon path={mdiAngleAcute} size={iconSize} color={toolActive == "Angle" ? "green" : iconColor} />
                  </ListItemIcon>
                  <ListItemText classes={primaryClass} primary="Angle" />
                </ListItem>
                <ListItem button onClick={() => this.toolExecute("EllipticalRoi")} disabled={false}>
                  <ListItemIcon>
                    <Icon path={mdiEllipse} size={iconSize} color={toolActive == "EllipticalRoi" ? "green" : iconColor} />
                  </ListItemIcon>
                  <ListItemText classes={primaryClass} primary="Elliptical Roi" />
                </ListItem>
                <ListItem button onClick={() => this.toolExecute("RectangleRoi")} disabled={false}>
                  <ListItemIcon>
                    <Icon path={mdiRectangle} size={iconSize} color={toolActive == "RectangleRoi" ? "green" : iconColor} />
                  </ListItemIcon>
                  <ListItemText classes={primaryClass} primary="Rectangle Roi" />
                </ListItem>
                <ListItem button onClick={() => this.toolExecute("FreehandRoi")} disabled={false}>
                  <ListItemIcon>
                    <Icon path={mdiGesture} size={iconSize} color={toolActive == "FreehandRoi" ? "green" : iconColor} />
                  </ListItemIcon>
                  <ListItemText classes={primaryClass} primary="Freehand Roi" />
                </ListItem>
                <Collapse in={openImageEdit} timeout="auto" unmountOnExit>
                  <List dense={true} component="div">
                    <ListItem button style={{ paddingLeft: 30 }} onClick={() => this.toolExecute("Invert")}>
                      <ListItemIcon>
                        <Icon path={mdiInvertColors} size={iconSize} color={toolActive == "Invert" ? "green" : iconColor} />
                      </ListItemIcon>
                      <ListItemText classes={primaryClass} primary="Invert" />
                    </ListItem>
                  </List>
                </Collapse>
              </List>
            </div>
          </Drawer>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    localfileStore: state.dicom.localfileStore,
    isOpen: state.dicom.isOpen,
    tool: state.dicom.tool,
    activeDcmIndex: state.dicom.activeDcmIndex,
    activeShow: state.dicom.activeShow,
    measurements: state.dicom.measurements,
    layout: state.dicom.layout,
    dicomdir: state.dicom.dicomdir,
    fsZippedFile: state.dicom.fsZippedFile,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    activeShowValue: (status) => dispatch(activeShowValue(status)),
    setLocalfileStore: (file) => dispatch(localfileStore(file)),
    toolStore: (tool) => dispatch(dcmTool(tool)),
    isOpenStore: (value) => dispatch(dcmIsOpen(value)),
    setActiveDcm: (dcm) => dispatch(activeDcm(dcm)),
    setActiveDcmIndex: (index) => dispatch(activeDcmIndex(index)),
    setActiveMeasurements: (measurements) => dispatch(activeMeasurements(measurements)),
    setLayoutStore: (row, col) => dispatch(setLayout(row, col)),
    setDicomdirStore: (dicomdir) => dispatch(setDicomdir(dicomdir)),
    setFsZippedFile: (file) => dispatch(setZippedFile(file)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(App));

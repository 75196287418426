import { Select } from "antd";
import React, { useEffect, useState } from "react";
import { ExpandSVG } from "src/common/components/icons/SVGIcon";
import DataVASCULOQOL from "../../../data/VASCULOQOL";
import { useSelector } from "react-redux";
import "./index.scss";

const { Option } = Select;

const VASCULOQOL = ({ onChangeData, ...restProps }) => {
  const [dataQuest, setDataQuest] = useState(DataVASCULOQOL);
  const [error, setError] = useState([false, false, false, false, false, false]);

  const vascuqol6 = useSelector((state) => state.patientExamination.vascuqol6);

  const handleChange = (data) => {
    let temp = [];
    data.map((r) => {
      temp.push({
        type: r.key,
        value: r?.value,
      });
    });
    onChangeData(temp);
    setDataQuest(data);
    let temp2 = error;
    temp.map((r, index) => {
      if (r?.value === "" || r?.value === null || r?.value === undefined) {
        temp2[index] = true;
      } else {
        temp2[index] = false;
      }
    });
    setError([...temp2]);
  };

  useEffect(() => {
    let dataQuestMix = dataQuest || [];
    if (vascuqol6?.length > 0) {
      vascuqol6.map((r) => {
        const indexQuest = dataQuest.findIndex((quest) => quest?.key === r?.type);
        if (indexQuest != -1) {
          dataQuestMix[indexQuest]["value"] = r?.value;
        }
      });
    } else {
      dataQuestMix = dataQuest.map((r) => {
        return {
          ...r,
          value: undefined,
        };
      });
    }
    setDataQuest([...dataQuestMix]);
    const temp = error;
    dataQuestMix.map((r, index) => {
      if (r?.value === "" || r?.value === null || r?.value === undefined) {
        temp[index] = true;
      } else {
        temp[index] = false;
      }
    });
    setError([...temp]);
  }, [vascuqol6]);

  return (
    <div className="resourceContent">
      {dataQuest.map((r, index) => {
        return (
          <div key={`${r?.question}-${index}`} style={{ width: "100%" }}>
            <div className="resourceInfo between half-pannel" style={{ alignItems: "flex-start" }}>
              <div style={{ width: "calc(100% - 250px)" }}>{r?.question}</div>
              <div
                style={{ width: "250px", display: "flex", justifyContent: "center", alignItems: "center" }}
                className={`vascuqol ${error[index] == true ? "error" : ""}`}
              >
                <Select
                  placeholder="Response"
                  suffixIcon={<ExpandSVG />}
                  allowClear={false}
                  onChange={(e) => {
                    dataQuest[index]["value"] = e;
                    handleChange(dataQuest);
                  }}
                  value={r?.value}
                  style={{
                    width: "100%",
                    boxShadow: "none",
                    alignItems: "center",
                    height: "40px",
                    maxHeight: "none",
                    border: error[index] == true ? "2px solid #da1e28" : "1px solid #dfe3e6",
                    color: error[index] == true ? "#da1e28 !important" : "",
                  }}
                >
                  {(r?.dataChoices || []).map((item, indexChoice) => (
                    <Option key={`${item}-${index}-${indexChoice}`} value={item}>
                      <span style={{ textTransform: "capitalize" }}>{item}</span>
                    </Option>
                  ))}
                </Select>
              </div>
            </div>
          </div>
        );
      })}
    </div>
  );
};

export default VASCULOQOL;

import { Drawer } from "antd";
import moment from "moment";
import React, { useEffect, useMemo, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import Notes from "../../../common/components/Notes";
import { getLocal, setLocal } from "../../../helpers/Local";
import { validateSteps } from "../../../services/validations";
import {
  goToCharting,
  removeDataPatient,
  remove_patient_on_schedules,
  resetStateResource,
  selectPatient,
  setGlobalLoading,
  setPatientForRecords,
  setPatientSnapshotData,
  get_patient_notes,
} from "../../../store/actions";
import FormStepper from "../../components/FormStepper";
import { SummaryEmptySVG } from "../../components/icons/SVGIcon";
import PharmacyAddForm from "../../components/PharmacyAddForm";
import PharmacyEditForm from "../../components/PharmacyEditForm";
import ProviderAddForm from "../../components/ProviderAddForm";
import ProviderEditForm from "../../components/ProviderEditForm";
import ScrollWrapper from "../../components/ScrollBar";
import Toastr from "../../components/Toast";
import { newSnapshotOption } from "../../constants/initialData";
import Demographics from "./Demographics";
import InsuranceDetail from "./InsuranceDetail";
import MedicalHistory from "./MedicalHistory";

const initialCondition = [
  { step: "demographics", status: true },
  { step: "insurance", status: false },
  { step: "medical", status: false },
  { step: "notes", status: false },
];

const formSteps = [
  {
    title: "Demographics",
    isConfirmed: true,
    content: Demographics,
  },
  {
    title: "Insurance Details",
    isConfirmed: true,
    content: InsuranceDetail,
  },
  {
    title: "Medical History",
    isConfirmed: false,
    content: MedicalHistory,
  },
  {
    title: "Notes",
    isConfirmed: true,
    content: Notes,
  },
];

const formStepsProviderAdd = [
  {
    title: "Healthcare Provider Location",
    isConfirmed: false,
    content: ProviderAddForm,
  },
];

const formStepsProviderEdit = [
  {
    title: "Healthcare Provider Location Edit",
    isConfirmed: false,
    content: ProviderEditForm,
  },
];

const formStepsPharmacyAdd = [
  {
    title: "Pharmacy Information",
    isConfirmed: false,
    content: PharmacyAddForm,
  },
];

const formStepsPharmacyEdit = [
  {
    title: "Pharmacy Information Edit",
    isConfirmed: false,
    content: PharmacyEditForm,
  },
];

const SnapSidebar = React.forwardRef(
  ({ isShow, settup, sidebarIcon, handleCloseSidebar, opener, isProcedureProcessing, appointmentId, isShowGoto, ...restProps }, ref) => {
    const resource = useSelector((state) => state.patientSnapshot.resource);
    const locations = useSelector((state) => state.common.locations);
    const providers = useSelector((state) => state.common.only_providers);
    const stepApi = useSelector((state) => state.clinicProviderReview.step);
    const filteredLocations = useSelector((state) => state.common.filteredLocations);
    const filteredProviders = useSelector((state) => state.common.filteredProviders);
    const referralConditions = useSelector((state) => state.common.referralConditions);
    const patient_all_insurances_id = useSelector((state) => state.newPatient.patient_all_insurances_id);
    const referredProviders = useSelector((state) => state.common.referredProviders);
    const enterInsuranceCheck = useSelector((state) => state.newPatient.enterInsuranceCheck);
    const pharmacies = useSelector((state) => state.common.pharmacies);
    const history = useHistory();
    const pcpList = useSelector((state) => state.common.pcpList);
    const patientsNote = useSelector((state) => state.newPatient.patientsNote);
    const [isFluSeason, setIsFluSeason] = useState(false);
    const [isOver62, setIsOver62] = useState(false);
    const [isPharmacyEdit, setIsPharmacyEdit] = useState(false);
    const [valuePharmacyEdit, setValuePharmacyEdit] = useState(null);
    const isSave = useSelector((state) => state.patientPending.isSave);
    const sideNavStatus = useSelector((state) => state.common.sideNavStatus);
    const dispatch = useDispatch();

    const [step, setStep] = useState(0);
    const [isProviderAdding, setIsProviderAdding] = useState(false);
    const [isProviderEdit, setIsProviderEdit] = useState(false);
    const [isPharmacyAdding, setIsPharmacyAdding] = useState(false);
    const [valueProviderEdit, setValueProviderEdit] = useState(null);
    const [conditions, setConditions] = useState(initialCondition);
    const [timer, setTimer] = useState(0);
    const [isAddForm, setIsAddForm] = useState(false);
    const [stepData, setStepData] = useState({
      demographics: {},
      insurance: {},
      medical: {},
    });

    useEffect(() => {
      if (settup) {
        if (settup == -1) {
          setStep(0);
        } else {
          setStep(settup);
        }
      }
    }, [settup]);

    useEffect(() => {
      if (stepApi && stepApi.length > 0) {
        setStep(Number(stepApi - 1));
      }
    }, [stepApi]);

    const isToastr = useMemo(() => {
      if (isSave) {
        setTimer(0);
        setTimeout(() => {
          setTimer(600);
        }, 2000);
        return true;
      }
      return false;
    }, [isSave]);

    useEffect(() => {
      if (resource) {
        const a = moment();
        const b = moment(resource?.date_of_birth);
        if (a.diff(b, "years") >= 65) {
          setIsOver62(true);
        }
        if (Number(moment().format("MM")) < 10 && Number(moment().format("MM")) > 2) {
          setIsFluSeason(false);
        } else {
          setIsFluSeason(true);
        }
        setStepData({
          demographics: { ...resource },
          insurance: {},
          medical: {
            previous_surgical_history: resource?.previous_surgical_history || [],
            medications: resource?.medications || [],
            allergies: resource?.allergies || [],
            previous_medical_history: resource?.icd_codes || [],
            flu_vaccination: resource?.flu_vaccination,
            pneumococcal: resource?.pneumococcal,
            aortic_disease: resource?.aortic_disease,
            amputation: resource?.amputation,
            smoking_ppd: resource?.smoking_ppd,
            alcoholic_drink_pw: resource?.alcoholic_drink_pw,
            previous_smoking_ppd: resource?.previous_smoking_ppd,
            years_of_smoking: resource?.years_of_smoking,
            no_medication: resource?.no_medication,
            no_drug_allergies: resource?.no_drug_allergies,
            no_medical_history: resource?.no_medical_history,
            no_surgical_history: resource?.no_surgical_history,
          },
        });
      }
    }, [resource]);

    useEffect(() => {
      if (resource) {
        const newConditions = [...conditions];
        for (let i = 0; i < 3; i++) {
          const validate = validateSteps(stepData[conditions[i].step], newSnapshotOption[conditions[i].step]);
          newConditions[i].status = validate;
        }
        if (stepData["medical"]) {
          if (
            stepData["medical"]["alcoholic_drink_pw"] != "" &&
            ((stepData["medical"]["allergies"] && stepData["medical"]["allergies"].length > 0) || stepData["medical"]["no_drug_allergies"] == true) &&
            stepData["medical"]["amputation"] != -1 &&
            (isFluSeason ? stepData["medical"]["flu_vaccination"] != -1 : stepData["medical"]["flu_vaccination"] != null) &&
            (isOver62
              ? stepData["medical"]["pneumococcal"] != -1
              : stepData["medical"]["pneumococcal"] != null ||
                stepData["medical"]["pneumococcal"] == -1 ||
                stepData["medical"]["pneumococcal"] == null) &&
            stepData["medical"]["aortic_disease"] != -1 &&
            ((stepData["medical"]["medications"] && stepData["medical"]["medications"].length > 0) || stepData["medical"]["no_medication"] == true) &&
            ((stepData["medical"]["previous_medical_history"] && stepData["medical"]["previous_medical_history"].length > 0) ||
              stepData["medical"]["no_medical_history"] == true ||
              stepData["medical"]["no_medical_history"] == 1) &&
            stepData["medical"]["previous_smoking_ppd"] != "" &&
            ((stepData["medical"]["previous_surgical_history"] && stepData["medical"]["previous_surgical_history"].length > 0) ||
              stepData["medical"]["no_surgical_history"] == true ||
              stepData["medical"]["no_surgical_history"] == 1) &&
            (stepData["medical"]["smoking_ppd"] != "" || stepData["medical"]["smoking_ppd"] == 0) &&
            stepData["medical"]["years_of_smoking"] != ""
          ) {
            newConditions[2].status = true;
          } else {
            newConditions[2].status = false;
          }
        }
        if (
          patient_all_insurances_id &&
          patient_all_insurances_id.some(
            (variable) => variable.is_verified && variable.is_verified === 1 && variable.is_eligible && variable.is_eligible === 1
          )
        ) {
          newConditions[1].status = true;
        } else {
          newConditions[1].status = false;
        }
        if (enterInsuranceCheck) {
          newConditions[1].status = true;
        }
        if (patientsNote && patientsNote.length > 0) {
          newConditions[3].status = true;
        } else {
          newConditions[3].status = false;
        }
        setConditions(newConditions);
      }
    }, [resource, step, stepData, patient_all_insurances_id, isFluSeason, isOver62, enterInsuranceCheck, patientsNote]);

    useEffect(() => {
      setIsProviderAdding(false);
      setIsProviderEdit(false);
      setValueProviderEdit(null);
      setIsPharmacyEdit(false);
      setValuePharmacyEdit(null);
    }, [resource]);

    const setProviderAddForm = (value) => {
      setIsProviderAdding(value);
    };

    const setProviderEditForm = (status, valueDetail) => {
      setIsProviderEdit(status);
      setValueProviderEdit(valueDetail);
    };

    const setPharmacyAddForm = (value) => {
      setIsPharmacyAdding(value);
    };

    const setPharmacyEditForm = (value, valueDetail) => {
      setIsPharmacyEdit(value);
      setValuePharmacyEdit(valueDetail);
    };

    const handleSubmit = () => {
      if (step < 4) {
        setStep(step);
      } else {
        // Todo: handleSubmit
      }
    };

    const handleClickGo = async () => {
      // move on
      // dispatch
      dispatch(
        goToCharting({
          isGoToCharting: true,
          appointmentIdGoToCharting: appointmentId,
        })
      );
      dispatch(setGlobalLoading(true));
      await dispatch(removeDataPatient());
      await dispatch(resetStateResource());
      await dispatch(remove_patient_on_schedules());
      await dispatch(setPatientForRecords(resource?.patient_id || resource?.id));
      const check = await dispatch(selectPatient(resource?.patient_id || resource?.id));
      await dispatch(setPatientSnapshotData("resource", check));
      await dispatch(get_patient_notes(resource?.patient_id || resource?.id));
      await setLocal("current_patient_id", resource?.patient_id || resource?.id);

      dispatch(setGlobalLoading(false));
      if (check) {
        history.push("/patient-records/clinical");
      } else {
        alert("something wrong please try again!");
      }
    };

    if (!resource) {
      return (
        <Drawer title="PATIENT" placement="right" closable onClose={handleCloseSidebar} visible={isShow} destroyOnClose>
          <div ref={ref} className="resourceContainer new">
            <div
              className="emptyContentIcon"
              style={{
                width: "100%",
                height: "calc(100% - 160px)",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <SummaryEmptySVG />
            </div>
          </div>
        </Drawer>
      );
    }

    const { first_name, last_name, cancelled_reason, deceased } = resource;
    const redReasons = ["Deceased", "Death", "Changed Provider"];

    return (
      <Drawer
        title={
          isAddForm
            ? "ADD NEW PATIENT"
            : `PATIENT DASHBOARD - ${first_name} ${last_name} ${getLocal("current_appointment_id") ? `[${getLocal("current_appointment_id")}]` : ""}`
        }
        placement="right"
        className={`${sideNavStatus ? "wide" : ""} ${deceased == "Yes" || (redReasons.includes(cancelled_reason) && cancelled_reason) ? "red" : ""}`}
        closable={restProps?.notPermanent ? true : false}
        mask={restProps?.notPermanent ? true : false}
        visible={isShow}
        onClose={restProps?.notPermanent ? handleCloseSidebar : ""}
        destroyOnClose
      >
        <div ref={ref} className="resourceContainer new">
          {isToastr && timer < 600 && (
            <div
              style={{
                position: "fixed",
                top: "60px",
                right: "2px",
                zIndex: 10,
              }}
            >
              <Toastr type="New Patient" status="success" msg="Added New Patient Successfully!12" />
            </div>
          )}

          <ScrollWrapper css="x-hidden">
            <FormStepper
              steps={
                isProviderAdding
                  ? formStepsProviderAdd
                  : isPharmacyAdding
                  ? formStepsPharmacyAdd
                  : isPharmacyEdit
                  ? formStepsPharmacyEdit
                  : isProviderEdit
                  ? formStepsProviderEdit
                  : isPharmacyEdit
                  ? formStepsPharmacyEdit
                  : formSteps
              }
              isProcedureProcessing={isProcedureProcessing}
              setProviderAddForm={setProviderAddForm}
              setProviderEditForm={setProviderEditForm}
              setPharmacyAddForm={setPharmacyAddForm}
              setPharmacyEditForm={setPharmacyEditForm}
              currentStep={step}
              valueProviderEdit={valueProviderEdit}
              valuePharmacyEdit={valuePharmacyEdit}
              customResource={resource}
              handleStep={setStep}
              handleContinue={handleSubmit}
              locations={locations}
              providers={providers}
              filteredLocations={filteredLocations}
              filteredProviders={filteredProviders}
              conditions={conditions}
              referredProviders={referredProviders}
              referralConditions={referralConditions}
              pcpList={pcpList && pcpList.active_pcp ? pcpList.active_pcp : pcpList}
              isScan={restProps.isScan}
              setOpenAddForm={setIsAddForm}
              isAddForm={isAddForm}
              pharmacies={pharmacies}
            />
            {isShowGoto ? (
              <div style={{ paddingBottom: "2rem", width: "100%" }}>
                <div className="actionContent">
                  <button onClick={handleClickGo} className="common-btn">
                    Go to Patient Charting
                  </button>
                </div>
              </div>
            ) : null}
          </ScrollWrapper>
        </div>
      </Drawer>
    );
  }
);

export default SnapSidebar;

import React, { useEffect, useState } from "react";
import moment from "moment";
import { useSelector, useDispatch } from "react-redux";
import DataTable from "../../../common/components/DataTable/DataTable";
import ToastMessage from "../../../common/components/Toast";
import { Icon } from "antd";
import {
  chooseSelectedPatientResource,
  get_patient_notes,
  getAppointmentInsurance,
  getPatientSchedules,
  patient_insurances_id_insurances,
  setActionActive,
  setDataPatient,
  setGlobalLoading,
  setGlobalToastr,
} from "src/store/actions";
import { markInsuranceVerified } from "src/services/api.services";
import { setLocal } from "src/helpers/Local";
import { useHistory } from "react-router-dom";

const AppointmentInsurance = () => {
  const appointment_insurance = useSelector((state) => state.procedure.appointment_insurance);
  const errorRest = useSelector((state) => state.common.globalErrorMessage);
  const history = useHistory();
  const [tableLoad, setTableLoads] = useState([]);
  const [isToastr, setIsToastr] = useState(false);
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(setGlobalLoading(true));
    dispatch(getAppointmentInsurance());
  }, []);

  useEffect(() => {
    if (appointment_insurance) {
      setTableLoads(appointment_insurance);
      dispatch(setGlobalLoading(false));
    }
  }, [appointment_insurance]);

  const showToast = () => {
    return <ToastMessage type="Prior Authorizations" status={"success"} msg={"Prior Authorization Saved!"} />;
  };

  const handleVerify = async (row, index) => {
    await markInsuranceVerified(row?.patient_insurance_id);
    dispatch(getAppointmentInsurance());
  };

  const handleClick = async (row, index) => {
    dispatch(setGlobalLoading(true));
    const result = await dispatch(chooseSelectedPatientResource(row?.patient_id));
    if (result) {
      await dispatch(setDataPatient(result));

      dispatch(
        setActionActive({
          status: true,
          value: row?.appointment_date,
        })
      );
      setLocal("current_appointment_id", row?.id);
      setLocal("current_patient_id", row?.id);
      await dispatch(patient_insurances_id_insurances(row?.patient_id));
      await dispatch(get_patient_notes(row?.patient_id));
      dispatch(setGlobalLoading(false));
      if (row?.type === "angio") {
        await dispatch(
          getPatientSchedules({
            date: row?.appointment_date ? moment(row?.appointment_date).format("MM/DD/YYYY") : null,
            location: "",
            provider_id: "",
            patient_id: "",
            only_clinics: 0,
            only_procedures: 1,
            only_us: 0,
            only_angio: 1,
          })
        );
        history.push("/obl-workflow/check-in");
      } else {
        await dispatch(
          getPatientSchedules({
            date: row?.appointment_date ? moment(row?.appointment_date).format("MM/DD/YYYY") : null,
            location: "",
            provider_id: "",
            patient_id: "",
            only_clinics: 1,
            only_procedures: 0,
            only_us: 1,
            only_angio: 0,
          })
        );
        history.push("/clinic-workflow/check-in");
      }
    } else {
      dispatch(setGlobalLoading(false));
      dispatch(
        setGlobalToastr({
          header: "Patient not found!",
          type: "warning",
        })
      );
    }
  };

  return (
    <div className="tableContainer">
      {isToastr && !errorRest && showToast()}
      <DataTable
        title="appointment_insurance"
        hideHeader={false}
        isHideTableCss={true}
        labels={["Name", "Appointment Date", "Appointment Type", "Insurance", "Eligible", "Action"]}
        widths={["23%", "12.83%", "12.83%", "12.83%", "12.83%", "12.83%"]}
        columns={[
          { sortable: false, key: "name" },
          { sortable: false, key: "appointment_date" },
          { sortable: false, key: "appointment_type" },
          { sortable: false, key: "insurance" },
          { sortable: false, key: "eligible" },
          { sortable: false, key: "actions" },
        ]}
        defaultActive={-1}
        disableDelete={true}
        rowData={(tableLoad || []).map((row, index) => {
          return {
            id: row?.id,
            patient_id: row?.patient_id,
            name: (
              <div
                className="iconField td with-icon"
                style={{
                  display: "flex",
                  paddingLeft: "0.25rem",
                  justifyContent: "flex-start",
                  alignItems: "center",
                }}
              >
                <div className="d-flex">
                  {row?.eligible == "Unverified" && <Icon type="question-circle" theme="twoTone" twoToneColor="#FFC107" />}
                  {row?.eligible == "Verified" && <Icon type="check-circle" theme="twoTone" twoToneColor="#24a148" />}
                  {row?.eligible == "Invalid" && <Icon type="warning" theme="twoTone" twoToneColor="#DA1E28" />}
                </div>
                <p style={{ paddingLeft: "0.75rem" }}>{row?.patient_name}</p>
              </div>
            ),
            appointment_date: moment(row?.appointment_date).format("MM/DD/YYYY hh:mm A"),
            appointment_type: row?.appointment_type,
            insurance: row?.insurance,
            eligible: (
              <span
                onClick={() => {
                  handleClick(row);
                }}
                style={{
                  cursor: "pointer",
                  color: "rgb(15, 98, 254)",
                  fontWeight: "bold",
                }}
              >
                {row?.eligible}
              </span>
            ),
            actions: (
              <div className="iconField td with-icon">
                {(row?.eligible == "Unverified" || row?.eligible == "Invalid") && (
                  <span onClick={() => handleVerify(row, index)} style={{ cursor: "pointer", color: "rgb(15, 98, 254)", fontWeight: "bold" }}>
                    Verify
                  </span>
                )}
              </div>
            ),
          };
        })}
      ></DataTable>
    </div>
  );
};

export default AppointmentInsurance;

import { Checkbox } from "antd";
import moment from "moment";
import React, { Component } from "react";
import { connect } from "react-redux";
import { HPIItem } from "../../../../common/components/AutoAccordionProcedure";
import ScrollWrapper from "../../../../common/components/ScrollBar";
import { getLocal } from "../../../../helpers/Local";
import { angioPatientSnapshotLoadInit, patientExaminationSave, setSidebarState, setGlobalLoading, load_procedurals } from "../../../../store/actions";
import { PostOperativesTemp, StaticResource } from "../../constant";
import PostOperativeSideBar from "./PostOperativeSidebar";

export class PostOperative extends Component {
  state = {
    isSidebar: window.innerWidth <= 768 ? false : true,
    dataAll: {},
    post_operative_procedure_data: {},
    procedure_id_event: null,
    procedure_id: null,
    post_operations_state: [],
    dataPost: [],
    checkedAllNormalsP: false,
    firstCheck: false,
  };

  timer = null;

  arrCheckRender = (valueCheck) => {
    let dataCheck = [];
    let name = "";
    const arraycheck = (valueCheck || []).map((r) => {
      if (r.type) {
        if (r.type === "Patient Admission") {
          name = "patient_admission";
        } else if (r.type === "NPO Status") {
          name = "npo_status";
        } else if (r.type === "Contrast Allergy") {
          name = "contrast_allergy";
        } else if (r.type === "Latex Allergy") {
          name = "latex_allergy";
        } else if (r.type === "Anticoagulation") {
          name = "anticoagulation";
        } else if (r.type === "Last Anticoagulation") {
          name = "last_anticoagulation";
        } else if (r.type === "Breath Sounds") {
          name = "breath_sounds";
        } else if (r.type === "Respiratory Pattern") {
          name = "respiratory_pattern";
        } else if (r.type === "Cardiac") {
          name = "cardiac";
        } else if (r.type === "INR") {
          name = "inr";
        } else if (r.type === "Creatinine") {
          name = "creatinine";
        } else if (r.type === "ASA Classification") {
          name = "asa_classification";
        } else if (r.type === "Reviewed") {
          name = "reviewed";
        } else if (r.type === "Informed") {
          name = "informed";
        } else if (r.type == "Recovery Entry Time") {
          name = "recovery_entry_time";
        } else if (r.type == "Recovery Bed") {
          name = "recovery_bed";
        } else if (r.type == "Accepting Nurse") {
          name = "accepting_nurse";
        } else if (r.type == "Notes") {
          name = "notes";
        } else {
          name = r.type;
        }
        dataCheck = [
          ...dataCheck,
          {
            field_name: name,
            field_value: r?.value || r?.values || "",
            default_comments: r.default_comments,
            comments: r.comments,
          },
        ];
      }
      if (r.type == "Respiratory" || r.type == "Cardiovascular" || r.type == "Constitutional" || r.type == "Neurological") {
        return {
          type: r.type,
          comments: r.comments,
          default_comments: r.default_comments,
          extra: r.extra,
          values: r?.value || r?.values || "",
        };
      } else {
        return {
          type: r.type,
          comments: r.comments,
          default_comments: r.default_comments,
          extra: r.extra,
          value: r?.value || r?.values || "",
        };
      }
    });
    return { arraycheck, dataCheck };
  };

  fetchCheck = async () => {
    const id = getLocal("current_appointment_id");
    await this.props.setGlobalLoading(true);
    await this.props.load_procedurals(id);
    const post = await this.props.angioPatientSnapshotLoadInit(id);
    if (post) {
      if (post?.all_normal_post_operative == true || post?.all_normal_post_operative == 1) {
        this.setState({
          checkedAllNormalsP: true,
        });
      } else {
        this.setState({
          checkedAllNormalsP: false,
        });
      }
      if (post?.post_operative) {
        const { arraycheck, dataCheck } = this.arrCheckRender(post?.post_operative);
        this.setState({ dataPost: arraycheck });
        let neftData = {};
        dataCheck.map((r) => {
          neftData = { ...neftData, [r?.field_name]: r?.field_value };
        });
        this.setState({ dataAll: neftData });
        if (dataCheck) {
          this.setState({
            post_operative_procedure_data: { fields: dataCheck },
          });
        }
      }
    }
    await this.props.setGlobalLoading(false);
  };

  async componentDidMount() {
    this.setState({
      procedure_id_event: getLocal("current_appointment_id"),
      procedure_id: getLocal("current_patient_id"),
    });
    this.props.setSidebarState(true);
    this.fetchCheck();
  }

  componentWillUnmount() {
    this.props.setSidebarState(false);
  }

  handleSidebar = () => {
    this.setState({ isSidebar: true });
  };

  handleCloseSidebar = () => {
    this.setState({ isSidebar: false });
  };

  toggleSidebar = () => {
    this.setState({ isSidebar: !this.state.isSidebar });
  };

  onChangeOperations = async (value, type) => {
    const { post_operations_state, dataAll } = this.state;
    this.setState({ dataAll: { ...dataAll, [type]: value } });
    const operationIndex = post_operations_state.findIndex((pre) => pre.type === type);
    if (operationIndex > -1) {
      post_operations_state[operationIndex] = { type, value };
    } else {
      post_operations_state.push({ type, value });
    }
    this.setState({ post_operations_state });
    post_operations_state.map((r) => {
      const indexcheck = (this.state.dataPost || []).findIndex((index) => index.type == r.type);
      if (indexcheck != -1 && this.state.dataPost[indexcheck]) {
        this.state.dataPost[indexcheck]["value"] = r?.value;
      }
    });
    if (this.props.dataPostOpCheckAll) {
      this.props.dataPostOpCheckAll([...(this.state.dataPost || [])], this.state.checkedAllNormalsP);
    }
  };

  onChangeTimePicker = (value, type) => {
    const { dataAll } = this.state;
    const time = moment(value, "hh:mm A").format("hh:mm A");
    if (type === "Recovery Entry Time") {
      this.setState({ dataAll: { ...dataAll, recovery_entry_time: time } });
    }
    this.onChangeOperations(time, type);
  };

  onChangeSelect = (value, type) => {
    const { dataAll } = this.state;
    if (type === "Recovery Bed") {
      this.setState({ dataAll: { ...dataAll, recovery_bed: value } });
    } else if (type === "Accepting Nurse") {
      this.setState({ dataAll: { ...dataAll, accepting_nurse: value } });
    }
    this.onChangeOperations(value, type);
  };

  clickChangeRow = (value, type) => {
    const { dataAll } = this.state;
    if (type === "Breath Sounds") {
      this.setState({
        dataAll: {
          ...dataAll,
          breath_sounds:
            dataAll && dataAll["breath_sounds"] && dataAll["breath_sounds"].some((r) => r === value)
              ? [...(dataAll["breath_sounds"] || []).filter((r) => r !== value)]
              : [...(dataAll["breath_sounds"] || []), value],
        },
      });
    } else if (type === "Respiratory Pattern") {
      this.setState({
        dataAll: {
          ...dataAll,
          respiratory_pattern:
            dataAll && dataAll["respiratory_pattern"] && dataAll["respiratory_pattern"].some((r) => r === value)
              ? [...(dataAll["respiratory_pattern"] || []).filter((r) => r !== value)]
              : [...(dataAll["respiratory_pattern"] || []), value],
        },
      });
    } else if (type === "Cardiac") {
      this.setState({
        dataAll: {
          ...dataAll,
          cardiac:
            dataAll && dataAll["cardiac"] && dataAll["cardiac"].some((r) => r === value)
              ? [...(dataAll["cardiac"] || []).filter((r) => r !== value)]
              : [...(dataAll["cardiac"] || []), value],
        },
      });
    }
    this.onChangeOperations(value, type);
  };

  onChangeInput = (value, type) => {
    const { dataAll } = this.state;
    if (type === "Notes") {
      this.setState({ dataAll: { ...dataAll, notes: value } });
    }
    this.onChangeOperations(value, type);
  };

  onChangeCommentCheck = (value, type) => {
    const { dataPost, post_operative_procedure_data } = this.state;
    const indexcheck = (dataPost || []).findIndex((index) => index.type === type);
    if (indexcheck >= 0 && dataPost[indexcheck]) {
      dataPost[indexcheck]["comments"] = value;
      if (
        post_operative_procedure_data &&
        post_operative_procedure_data?.fields &&
        post_operative_procedure_data.fields[indexcheck] &&
        post_operative_procedure_data?.fields.length > 0
      ) {
        post_operative_procedure_data.fields[indexcheck]["comments"] = value;
        this.setState({
          post_operative_procedure_data: post_operative_procedure_data,
        });
      }
      clearTimeout(this.timer);
      this.timer = setTimeout(() => {
        this.setState({ firstCheck: true });
      }, 500);
      if (this.state.firstCheck) {
        if (this.props.dataPostOpCheckAll) {
          this.props.dataPostOpCheckAll([...(this.state.dataPost || [])], this.state.checkedAllNormalsP);
        }
      }
      this.setState({ post_operative_procedure_data, dataPost });
    }
  };

  handleUnClick = (value, type, isMulti) => {
    const { dataAll, post_operative_procedure_data } = this.state;
    if (!isMulti) {
      // fix when unCheck with not multi
      const neftType = type && type.length > 0 ? type.toLowerCase().replaceAll(" ", "_") : "";
      const dataNeft = [...(post_operative_procedure_data?.fields || [])];
      if ((dataAll && dataAll[neftType] && dataAll[neftType] === value) || (dataAll && dataAll[type] && dataAll[type] === value)) {
        if (dataNeft && dataNeft.length > 0) {
          // uncheck = fix data local and data props
          const findIndexNeft = dataNeft.findIndex((r) => r?.field_name == neftType || r?.field_name == type);
          const findData = dataNeft.find((r) => r?.field_name == neftType || r?.field_name == type);
          if (findIndexNeft != -1 && findData?.field_name) {
            dataNeft.splice(findIndexNeft, 1, {
              ...findData,
              field_value: "",
            });
            this.setState({
              post_operative_procedure_data: { fields: dataNeft },
            });
            this.clickChangeRow(null, type);
          }
        }
      } else {
        if (dataNeft && dataNeft.length > 0) {
          // update check = fix data local and data props
          const findIndexNeft = dataNeft.findIndex((r) => r?.field_name == neftType || r?.field_name == type);
          const findData = dataNeft.find((r) => r?.field_name == neftType || r?.field_name == type);
          if (findIndexNeft != -1 && findData?.field_name) {
            dataNeft.splice(findIndexNeft, 1, {
              ...findData,
              field_value: value,
            });
            this.setState({
              post_operative_procedure_data: { fields: dataNeft },
            });
          }
        }
        this.clickChangeRow(value, type);
      }
    } else {
      this.clickChangeRow(value, type);
    }
  };

  updateWhenChecked = (e) => {
    this.setState({
      checkedAllNormalsP: e.target.checked,
    });
    // r-leg-1, Right Common Femoral Artery => Right Femoral Pulse
    // l-leg-1, Left Common Femoral Artery => Left Femoral Pulse
    // "r-leg-4": "Right Popliteal Artery", => Right Popliteal Pulse
    // "l-leg-4": "Left Popliteal Artery", => Left Popliteal Pulse
    // "r-leg-7r": "Right Mid Posterior Tibial Artery", => Right Posterior Tibial Pulse
    //  "l-leg-7l": "Left Mid Posterior Tibial Artery", => Left Posterior Tibial Pulse
    //  "r-leg-7l": "Right Mid Anterior Tibial Artery", => Right Anterior Tibial Pulse
    // "l-leg-7r": "Left Mid Anterior Tibial Artery", => Left Anterior Tibial Pulse
    // array này sẽ dc so sánh để thay đổi dựa trên thông tin trên nếu có sự tốt hơn thì phải giảm
    const dataCheckOptions = {
      "Right Femoral Pulse": "r-leg-1",
      "Left Femoral Pulse": "l-leg-1",
      "Right Popliteal Pulse": "r-leg-4",
      "Left Popliteal Pulse": "l-leg-4",
      "Right Posterior Tibial Pulse": "r-leg-8r",
      "Left Posterior Tibial Pulse": "l-leg-8l",
      "Right Anterior Tibial Pulse": "r-leg-8l",
      "Left Anterior Tibial Pulse": "l-leg-8r",
    };
    const arrCheck = this.props.vessel_states_angio || [];
    if (e.target.checked) {
      let valueRPT = arrCheck.find((r) => r?.type == "Right Dorsalis Pedis")?.value || null;
      let valueLPT = arrCheck.find((r) => r?.type == "Left Dorsalis Pedis")?.value || null;
      // thêm 2 thành phần này nếu như data ko có
      if (!arrCheck.some((r) => r?.type == "Right Anterior Tibial Pulse")) {
        arrCheck.push({
          type: "Right Anterior Tibial Pulse",
          value: valueRPT,
        });
      }
      if (!arrCheck.some((r) => r?.type == "Left Anterior Tibial Pulse")) {
        arrCheck.push({
          type: "Left Anterior Tibial Pulse",
          value: valueLPT,
        });
      }
      //
      const fixData = (this.state.dataPost || []).map((r) => {
        // lấy vị trí giá trị cũ từ vessel_states_angio
        const findIndexFix = (arrCheck || []).findIndex((arr) => r?.type.includes(arr?.type));
        if (findIndexFix !== -1) {
          const value = arrCheck?.[findIndexFix]?.["value"] || null;
          // check option
          const detailOption = dataCheckOptions[r?.type] || null;
          if (detailOption) {
            const findIndexOption = (this.props.list_procedurals || []).findIndex((row) => row?.body?.part === detailOption);
            // nếu có 1 trong các options đặc biệt
            if (findIndexOption !== -1) {
              // check nếu better thì giảm 1 giá trị
              const medicalsStenosis = this.props.list_procedurals[findIndexOption]["medicals"]
                .filter((r) => r?.includes("stenosis-"))
                .map((r) => {
                  return {
                    key: r,
                    value: r.split("-")[1] ?? null,
                  };
                });
              if (
                medicalsStenosis &&
                medicalsStenosis.length > 1 &&
                parseInt(medicalsStenosis[0]?.["value"]) > parseInt(medicalsStenosis[medicalsStenosis.length - 1]?.["value"])
              ) {
                return {
                  ...r,
                  value: `${value == 0 ? "D" : value == "D" ? "1+" : value == 1 ? 2 : value}${value - 1 >= 0 ? "+" : ""}`,
                };
              } else {
                return {
                  ...r,
                  value: value == 2 || value == 1 ? `${value}+` : value,
                };
              }
            } else {
              return {
                ...r,
                value: value == 2 || value == 1 ? `${value}+` : value,
              };
            }
          }
        }
        if (r.type == "Notes") {
          return {
            ...r,
            value:
              "Sheath removed under direct visualization and pressure applied for greater than 20 minutes.  All hemostasis under control and there was no evidence of bleeding.  The proximal and distal pulses are intact and patients distal limb has full motor and sensation intact.",
          };
        }
        return {
          ...r,
        };
      });
      this.setState({
        dataPost: fixData,
        post_operative_procedure_data: {
          fields: fixData.map((r) => {
            return {
              comments: r?.comments,
              default_comments: r?.default_comments,
              field_name: r?.type,
              field_value: r?.value,
            };
          }),
        },
      });
      this.props.dataPostOpCheckAll([...(fixData || [])], e.target.checked);
    } else {
      const fixData = (this.state.dataPost || []).map((r) => {
        const findIndexFix = arrCheck?.findIndex((arr) => r?.type.includes(arr?.type));
        if (findIndexFix !== -1) {
          return {
            ...r,
            value: null,
          };
        } else {
          if (r.type === "Right Anterior Tibial Pulse" || r.type === "Left Anterior Tibial Pulse") {
            return {
              ...r,
              value: null,
            };
          } else if (r.type == "Notes") {
            return {
              ...r,
              value: null,
            };
          }
        }
        return {
          ...r,
        };
      });
      this.setState({
        dataPost: fixData,
        post_operative_procedure_data: {
          fields: fixData.map((r) => {
            return {
              comments: r?.comments,
              default_comments: r?.default_comments,
              field_name: r?.type,
              field_value: r?.value,
            };
          }),
        },
      });
      this.props.dataPostOpCheckAll([...(fixData || [])], e.target.checked);
    }
  };

  sidebarRef = null;
  constructor(props) {
    super(props);
    this.sidebarRef = React.createRef();
  }

  render() {
    const { isSidebar } = this.state;
    return (
      <div className="clinicWorkflow__main-section">
        <div className="eventsContainer has-sub-header" style={{ paddingTop: "1rem" }}>
          <ScrollWrapper css="no-padding x-hidden">
            <div className="conditionList" style={{ minHeight: "100%" }}>
              <Checkbox checked={this.state.checkedAllNormalsP} onChange={this.updateWhenChecked} disabled={this.props.is_locked}>
                Copy from Physical Exam
              </Checkbox>
              <div className="listSection">
                {PostOperativesTemp.map((condition, index) => (
                  <HPIItem
                    checkedAllNormalsP={this.state.checkedAllNormalsP}
                    mode={condition.mode}
                    isPost={true}
                    format={condition.format}
                    key={`ros-vitals-${index}`}
                    maxWidth={condition.maxWidth}
                    label={condition.label}
                    isIcon={condition.isIcon}
                    options={condition.options}
                    type={condition.type}
                    value={this.state.post_operative_procedure_data}
                    multiple={condition.multiple}
                    onChangeCommentCheck={(value, type) => {
                      this.onChangeCommentCheck(value, type);
                    }}
                    onChangeTimePicker={(value, type) => this.onChangeTimePicker(value, type)}
                    onChangeSelect={(value, type) => this.onChangeSelect(value, type)}
                    clickChangeRow={(value, type) => {
                      this.handleUnClick(value, type, condition.multiple);
                    }}
                    onChangeInput={(value, type) => {
                      this.onChangeInput(value, type);
                    }}
                    minWidth={"117px"}
                    isLocked={this.props.is_locked}
                  />
                ))}
              </div>
            </div>
          </ScrollWrapper>
        </div>
        {isSidebar === true && (
          <PostOperativeSideBar
            ref={this.sidebarRef}
            value={this.state.post_operative_procedure_data}
            resource={StaticResource}
            dataAll={this.state.dataAll}
            isShow={isSidebar}
            handleCloseSidebar={this.handleCloseSidebar}
          />
        )}
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  post_operative: state.patientExamination.post_operative,
  is_locked: state.common.is_locked,
  vessel_states_angio: state.patientExamination.vessel_states_angio,
  list_procedurals: state.procedure.list_procedurals,
});

const mapDispatchToProps = {
  patientExaminationSave,
  angioPatientSnapshotLoadInit,
  setSidebarState,
  setGlobalLoading,
  load_procedurals,
};

export default connect(mapStateToProps, mapDispatchToProps)(PostOperative);

import { Tabs } from "@material-ui/core";
import { Tooltip } from "antd";
import moment from "moment";
import React, { Component } from "react";
import { connect } from "react-redux";
import { FemaleSVG, MaleSVG } from "../../../common/components/icons/FaxSVG";
import PlanOrders from "../../../common/components/PlanOder/PlanOrders";
import TopBar from "../../../common/components/SubTopBar";
import Appointments from "../../../common/views/Appointment";
import Medications from "../../../common/views/Medications";
import PatientChart from "../../../common/views/PatientChart";
import PatientSnapshot from "../../../common/views/PatientSnapshot";
import { getLocal } from "../../../helpers/Local";
import { goToCharting, removeDataPatient, removeParamsChart, selectPatient, setDataPatient, setPatientForRecords } from "../../../store/actions";
import { TabHeaders } from "../constant";
import PatientCharting from "./clinicalModules/Charting";
import Communication from "./clinicalModules/Communication";
import Logs from "./clinicalModules/Logs";

class ClinicalModule extends Component {
  state = {
    activeTab: 1,
    patient_id: null,
    indexSlider: -1,
    indexDoc: -1,
    appointment_id: null,
  };

  fetchApiDetailReload = async () => {
    if (!this.props.patient_resource) {
      await this.props.removeDataPatient();
      const result = await this.props.setPatientForRecords(this.state.patient_id);
      if (result) {
        this.props.setDataPatient(result);
      }
    }
  };

  componentDidMount() {
    this.setState({
      patient_id: getLocal("current_patient_id"),
    });
    this.fetchApiDetailReload();
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps?.isGoToCharting) {
      this.setState({
        activeTab: 7,
        appointment_id: nextProps?.appointmentIdGoToCharting,
      });
    }
  }

  renderSideComponent = () => {
    const { patient_resource, resourceCheck } = this.props;
    if (!resourceCheck || !Object.keys(resourceCheck).length) {
      return null;
    }

    const fullName = (resourceCheck?.first_name + " " + resourceCheck?.last_name).toUpperCase();
    const age = resourceCheck?.date_of_birth ? "(" + moment().diff(resourceCheck?.date_of_birth, "years") + ")" : "";

    const allerrgies = [];
    if (resourceCheck?.allergies) {
      (resourceCheck?.allergies || []).forEach((allergy, index) => {
        const allergyName =
          allergy.reaction === "anaphylaxis" || allergy.status === "anaphylaxis" ? (
            <span
              style={{
                color: "red",
                fontSize: "0.625rem",
                fontWeight: 600,
              }}
              key={`${allergy?.id}-${allergy.allergy_name}`}
            >
              {allergy.allergy_name}
              {index < resourceCheck?.allergies.length - 1 ? ", " : ""}
            </span>
          ) : (
            <>
              {allergy.allergy_name}
              {index < resourceCheck?.allergies.length - 1 ? ", " : ""}
            </>
          );
        allerrgies.push(allergyName);
      });
    }

    return (
      <div
        style={{
          display: "flex",
          textAlign: "left",
          paddingTop: "0px",
          paddingRight: "12px",
          paddingLeft: "6px",
        }}
      >
        <div
          style={{
            color: "#152934",
            fontWeight: 400,
            fontSize: "1.125rem",
            lineHeight: "24px",
            whiteSpace: "nowrap",
          }}
        >
          {fullName} {age}
          {allerrgies.length === 0 && (
            <div
              style={{
                textAlign: "right",
                whiteSpace: "nowrap",
                lineHeight: "10px",
              }}
            >
              <span
                style={{
                  color: "#da1e28",
                  fontSize: "0.625rem",
                  fontWeight: 600,
                }}
              >
                {resourceCheck?.no_drug_allergies ? "NKDA" : "Pending Data"}
              </span>
            </div>
          )}
          {allerrgies.length > 0 && <br />}
          {allerrgies.length >= 1 && allerrgies.length < 3 && (
            <div
              style={{
                textAlign: "right",
                whiteSpace: "nowrap",
                lineHeight: "10px",
                color: "black",
                fontSize: "0.625rem",
                fontWeight: 600,
              }}
            >
              {allerrgies.map((allergy) => allergy)}
            </div>
          )}
          {allerrgies.length >= 3 && (
            <Tooltip placement="bottomRight" title={(resourceCheck?.allergies || []).map((allergy) => allergy?.allergy_name).join(", ")}>
              <div
                style={{
                  textAlign: "right",
                  whiteSpace: "nowrap",
                  lineHeight: "10px",
                  color: "black",
                  fontSize: "0.625rem",
                  fontWeight: 600,
                }}
              >
                {allerrgies.map((allergy) => allergy)}
              </div>
            </Tooltip>
          )}
        </div>

        {resourceCheck?.gender && resourceCheck?.gender.toUpperCase() == "MALE" ? (
          <MaleSVG style={{ marginLeft: "8px", height: "unset", width: "26px" }} />
        ) : (
          <FemaleSVG style={{ marginLeft: "8px", height: "unset", width: "26px" }} />
        )}
      </div>
    );
  };

  setAcitive = (value) => {
    this.setState({
      activeTab: value,
    });
  };

  render() {
    const { patient_resource } = this.props;
    const { activeTab } = this.state;
    const title = "Clinical Module";
    return (
      <div className="clinicWorkflow__main-page">
        <TopBar title={title} subStatus={""} sideComponent={this.renderSideComponent()} isBarcode={true} />
        <div className={`clinicWorkflow__main-container ${this.props.isSidebar ? "openSidebar" : ""}`}>
          <div className="clinicWorkflow__main-subTitle tabContainer">
            <div className="tabHeaders" style={{ height: "29px" }}>
              <Tabs
                TabIndicatorProps={{ style: { display: "none" } }}
                value={activeTab}
                scrollButtons="auto"
                variant="scrollable"
                aria-label="scrollable force tabs example"
              >
                {TabHeaders.map((tab, index) => (
                  <div
                    key={`tabHeaders-${tab.label}-${index}`}
                    className={`tabHeaders-item ${activeTab === index ? "active" : ""}`}
                    style={{ width: tab.width }}
                    onClick={() => {
                      this.setState({ activeTab: index });
                      this.props.removeParamsChart();
                    }}
                  >
                    {tab.label}
                  </div>
                ))}
              </Tabs>
            </div>
          </div>

          {activeTab === 0 && patient_resource && (
            <PatientSnapshot isShowGoto={true} patientResource={patient_resource} isScan={true} hasAppointment={false} />
          )}
          {activeTab === 1 && patient_resource && <PatientChart patientResource={patient_resource} />}
          {activeTab === 2 && patient_resource && <PlanOrders navigateToReview={() => {}} />}
          {activeTab === 3 && patient_resource && <Medications />}
          {activeTab === 4 && patient_resource && <Appointments patient_resource={patient_resource} />}
          {activeTab === 5 && <Communication />}
          {activeTab === 6 && <Logs />}
          {activeTab === 7 && (
            <PatientCharting
              setIndexSlider={(value) => {
                this.setState({
                  indexSlider: value,
                });
              }}
              indexSlider={this.state.indexSlider}
              setIndexDoc={(value) => {
                this.setState({
                  indexDoc: value,
                });
              }}
              indexDoc={this.state.indexDoc}
            />
          )}
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  patient_resource: state.patientRecords.resource,
  resourceCheck: state.patientSnapshot.resource,
  isSidebar: state.sidebar.isSidebarOpen,
  isGoToCharting: state.common.isGoToCharting,
});

const mapDispatchToProps = {
  setPatientForRecords,
  removeDataPatient,
  removeParamsChart,
  selectPatient,
  goToCharting,
  setDataPatient,
};

export default connect(mapStateToProps, mapDispatchToProps)(ClinicalModule);
